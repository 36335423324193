<template>
  <v-snackbar
    v-model="snackbar"
    light
    :color="snackbarColor"
    top
    right
  >
    <div class="text-center text-subtitle-1">
      {{ notificationText }}
    </div>
  </v-snackbar>
</template>

<script>
  import { eventBus, busEvents } from '@/EventBus'
  export default {
    name: 'AppNotification',
    data () {
      return {
        snackbar: false,
        notificationText: null,
        type: null,
      }
    },
    computed: {
      snackbarColor () {
        return this.type === 'error' ? 'ccred lighten-1' : 'primaryTeal'
      },
    },
    mounted () {
      eventBus.$on(busEvents.APP_NOTIFICATION, this.handler)
    },
    methods: {
      handler (data) {
        const { type, text } = data
        this.type = type
        this.notificationText = text
        this.snackbar = true
      },
    },
  }
</script>
